<!--文件上传组件-->
<template>
  <div class="upload-file">
    <el-upload
        ref="fileUpload"
        action="#"
        class="upload-demo"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :before-remove="beforeRemove"
        :on-success="handleSuccess"
        multiple
        :limit="10"
        :http-request="Upload"
        :file-list="fileList">
      <el-button size="small" type="primary">点击上传</el-button>
    </el-upload>
    <p>{{ uploadedFileName }}</p>
  </div>
</template>

<script name="uploadFile">

import axios from "axios";
import {onMounted} from "vue";
import Nav from "@/components/nav.vue";
export default {
  data() {
    return {
      fileList: [],
      uploadedFileName: '',
      testList:[]
    };
  },

  props:{
    name:{

    },
  },
  mounted() {
    console.log(this.props)
    this.init()
  },
  methods: {
    init(){
      this.fileList = [];
    },
    Upload(param) {
      const formData = new FormData();
      formData.append("file", param.file);
      let config = {
        method: "post",
        url: "/api/admin/sys-file/upload",
        headers: {
          'Authorization': 'Bearer '+  localStorage.getItem('access_token'),
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      };
      axios(config).then((response) => {
        this.fileList.push({
          name:param.file.name,
          url: response.data.data.url,
        });
        //this.uploadedFileName = param.file.name;
        // console.log(this.fileList);
        // this.fileList = this.fileList.filter((f) => f.url !== undefined).concat(this.testList.value);
        // param.onSuccess();
        this.$emit('childUpload', this.fileList, this.name);
      }).catch((response) => {
        param.onError();
      });

    },
    handleRemove(file, fileList) {
      this.fileList = fileList.filter((f) => !(f.name === file.name));
      this.$emit('childUpload', this.fileList, this.name);
    },
    handlePreview(file) {
      if(file.url.indexOf(".docx")!=-1 || file.url.indexOf(".doc")!=-1 || file.url.indexOf(".xlsx")!=-1  || file.url.indexOf(".csv")!=-1)
      {
        window.open("https://view.officeapps.live.com/op/view.aspx?src=https://land.dytudi.com.cn"+file.url)
      }else{
        this.$emit('on-preview', file);
      }

      // console.log(file);
      //this.$emit('on-preview', file);
    },
    handleSuccess(file, fileList) {
      console.log(22)
      console.log(file, fileList);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    }
  }
}

</script>
